@tailwind base;
@tailwind components;
@tailwind utilities;
.red-icon {
  color: red !important;
  border-color: red !important;
  outline-color: red !important;
}
.wishlist-icon {
  transition: all 0.3s ease;
}
.footer-area.reduced-h {
  height: 50vh; /* adjust this value as needed */
}

.slider-height {
  height: 600px !important; /* Ensure the height is applied */
  min-height: 600px !important; /* Forces a minimum height */
  display: flex;
  align-items: center; /* Centers content vertically */
}

.row.d-flex.align-items-center {
  align-items: center;
}
.sidebar-menu {
  width: 100%; /* Set the width to 100% of the screen width */
  height: 100vh; /* Set the height to 100% of the viewport height */
  position: fixed; /* Fix the position of the menu */
  top: 0; /* Position the menu at the top of the screen */
  left: 0; /* Position the menu at the left of the screen */
  background-color: #fff; /* Set a background color */
  overflow-y: auto; /* Add a scrollbar if the content overflows */
  transition: transform 0.3s ease-in-out; /* Add a transition for the transform property */
  transform: translateX(
    -100%
  ); /* Initially, move the menu to the left by its own width */
}

.sidebar-menu.open {
  transform: translateX(
    0
  ); /* When the menu is open, move it back to its original position */
}

#slide1 {
  background: url(./assets/necklace-photography.jpg);
  background-repeat: no-repeat;
  background-position: left;
}

#slide2 {
  background: url(./assets/vector-jewelry-realistic-banners.jpg);
  background-repeat: no-repeat;

  background-size: cover;
}
#slide3 {
  background: url(./assets/image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.quote-section {
  background: url(./assets/bluebg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
}

.quote-section .quote h1 {
  color: #fff;
  font-size: 40px;
  font-style: italic;
  line-height: 60px;
  width: 600px;
  padding: 120px 80px;
  font-family: "Times New Roman", Times, serif;
}
@media screen and (max-width: 500px) {
  .quote-section .quote h1 {
    font-size: 35px;
    line-height: 60px;
    width: auto;
    padding: 100px 30px;
  }
}
@media screen and (max-width: 350px) {
  .quote-section .quote h1 {
    font-size: 35px;
    line-height: 50px;
    width: auto;
    padding: 80px 30px;
  }
}

@media screen and (max-width: 1300px) {
  #btn-slider {
    margin-left: 50px;
  }
}

@media screen and (max-width: 575px) {
  #btn-slider {
    margin-left: 0px;
  }
}

.swiper-button-prev {
  left: 0 !important;
  margin: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
  margin: 0 !important;
}

.product-large-image-wrapper {
  position: relative;
}

.product-small-image-wrapper {
  height: 500px !important; /* Add some spacing between the main image and thumbnails */
}

.product-small-image-wrapper .single-image img {
  border-radius: 4px;
}
